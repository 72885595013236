.Sec1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  position: relative;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .dokumlerImg {
      width: 100%;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      h2 {
        position: absolute;
        z-index: 4;
        // margin-top: 8rem;
        color: white;
        background-color: black;
        padding: 8px;
        border-radius: 10px;
        margin-bottom: 1rem;
      }
      .dokumlerImg {
        width: 100%;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .Sec1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    margin-bottom: 20rem;
    div {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      .dokumlerImg {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      h2 {
        margin-top: 3rem;
        font-size: 1rem;
      }
    }
  }
}
