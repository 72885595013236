nav {
  position: absolute;
  margin-bottom: 2rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4%;
  width: 100%;

  .flags {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 0.5rem;
    margin-right: 2rem;
    span {
      width: 1rem;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
  .nav-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    a {
      position: fixed;
      margin-top: 1rem;
      img {
        width: 20%;
        margin-left: 30%;
      }
    }
  }
  .hamburger {
    position: absolute;
    z-index: 20;
    margin-left: auto;
    right: 4%;
    margin-right: 1%;
    ._icon {
      font-size: 3rem;
      cursor: pointer;
      color: #ff5714;
    }
  }
  .side-menu {
    position: absolute;
    border-radius: 15px;
    z-index: 10;
    width: 100vw;
    height: fit-content;
    // background-image: url(../../../public/assets/bg4.jpg);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 4rem;

    .menu-list {
      z-index: 10;
      ul {
        list-style: none;
        color: #ff5714;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        text-align: center;
        z-index: 10;
        li {
          h1 {
            font-weight: 400;
            padding-bottom: 2rem;
          }
          a {
            text-decoration: none;
            color: #ff5714;

            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }
            &:visited {
              color: #ff5714;
            }
          }
        }
      }
      .menu-list-contact {
        padding: 1rem;
        border-radius: 10px 0 0 10px;
        background-color: white;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        a {
          svg {
            font-size: 1.8rem;
            fill: #ff5714;
            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }
            &:visited {
              fill: #ff5714;
            }
          }
        }
      }
    }
    img {
      width: 15%;
      margin-bottom: 3rem;
      margin-top: 35rem;
    }
  }
}

@media screen and (max-width: 600px) {
  nav {
    position: absolute;
    margin-bottom: 2rem;
    z-index: 5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4%;
    .flags {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      gap: 0.3rem;
      span {
        width: 0.8rem;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
    .nav-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        img {
          width: 10rem;
          margin-left: -30%;
        }
      }
    }

    .hamburger {
      position: absolute;
      z-index: 20;
      margin-left: auto;
      // right: 4%;
      margin-right: 5%;
      ._icon {
        font-size: 2rem;
        cursor: pointer;
        color: #ff5714;
      }
    }
    .side-menu {
      .menu-list {
        ul {
          li {
            a {
              p {
                font-size: 20rem !important;
              }
            }
          }
        }
      }
      img {
        width: 50%;
        margin-top: 40rem;
      }
    }
  }
}
