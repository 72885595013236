.Sfero {
  .sfero_title {
    display: flex;
    img {
      width: 60%;
      height: 90vh;
    }
    div {
      width: 40%;
      height: 90vh;
      background-color: #581900;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 3rem;
      gap: 1rem;
      color: white;
      h1 {
        color: rgb(149, 92, 69);
      }
      a {
        p {
          color: #b8a097;
          background-color: black;
          padding: 4px 10px;
          border-radius: 20px;
          font-weight: 600;
          box-shadow: 1px 1px 1px rgb(149, 92, 69);
        }
      }
    }
  }
  .sfero_text {
    padding: 5rem;
    h1 {
      margin-bottom: 2rem;
      color: #c67657;
    }
    p {
      font-weight: 500;
      img {
        float: right;
        width: 50%;
        box-shadow: 10px 10px 10px #b8a097;
        margin: 1rem;
      }
    }
    margin-bottom: 10rem;
  }
  .altKisim {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .Sfero {
    .sfero_title {
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 50%;
      }
      div {
        width: 100%;
        height: 50%;
        background-color: #d2cfcf;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
      }
    }
    .sfero_text {
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;
          img {
            float: right;
            width: 90%;
            box-shadow: 10px 10px 10px #b8a097;
          }
        }
      }
      margin-bottom: 10rem;
    }
  }
}
