.Footer {
  // position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ff5714;
  z-index: 4;
  padding: 0 2rem 0 2rem;

  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 5px 10px #eca386;
  padding-right: 8rem;
  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      width: 40%;
      fill: white;
    }
  }
  p {
    font-size: 13px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 700;
    text-align: center;
  }
  .top-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .flags {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      gap: 0.5rem;
      span {
        width: 1rem;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
  .footer_contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    a {
      text-decoration: none;
      svg {
        fill: white;
        font-size: 1.8rem;
      }
      &:hover {
        opacity: 0.5;
      }
      img{
        width: 1.8rem;
      }
    }
  }
}

.hidden {
  position: fixed;
  bottom: 0;
  transition: 1s;
}

.visible {
  bottom: -15rem;
  transition: 2s;
}

// .false {
//   display: flex;
//   position: fixed;
//   bottom: 0;
//   transition: width 2s;
// }
// .hidden {
//   display: none;
//   bottom: -20rem;
// }

@media screen and (max-width: 500px) {
  .Footer {
    .top-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 10rem;
      }
    }
    p {
      font-size: 10px;
      margin-left: 1rem;
    }
    .footer_contact {
      display: none;
    }
  }
}
