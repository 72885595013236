// @import "~bootstrap/scss/bootstrap";
@import "swiper/css";
.siper-wrapper {
  display: flex;
  margin-top: 10rem;
  margin-bottom: 5rem;
  position: relative;
  .swiper {
    width: 60%;
    height: 40%;

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .siper-wrapper {
    display: flex;
    margin: 90% 0 10% 0;
    .swiper {
      width: 80%;
      height: 50%;

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .siper-wrapper {
    display: flex;
    margin-top: 90%;
    .swiper {
      width: 80%;
      height: 50%;

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        img {
          width: 100%;
        }
      }
    }
  }
}
