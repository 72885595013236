.AboutUs {
  img {
    width: 100%;
  }
  .hakkimizda {
    padding: 4.5rem;
    text-align: center;
    h1 {
      color: #c67657;
    }
    p {
      line-height: 1.8rem;
    }
  }
  .misyon {
    display: flex;
    padding: 4.5rem;
    gap: 2rem;
    div {
      width: 50%;
      h1 {
        color: #c67657;
        margin-bottom: 2rem;
      }
      p {
        line-height: 1.8rem;
      }
    }
    img {
      width: 50%;
      box-shadow: 10px 10px 10px #b8a097;
    }
  }
  .vizyon {
    display: flex;
    padding: 4.5rem;
    gap: 2rem;
    div {
      width: 50%;
      h1 {
        color: #c67657;
        margin-bottom: 2rem;
      }
      p {
        line-height: 1.8rem;
      }
    }
    img {
      width: 50%;
      box-shadow: 10px 10px 10px #b8a097;
    }
    .vizyon_img {
      display: none;
    }
    margin-bottom: 10rem;
  }
  .altKisim {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .AboutUs {
    img {
      width: 100%;
      height: 12rem;
    }
    .hakkimizda {
      padding: 4.5rem;
      text-align: center;
      h1 {
        color: #c67657;
      }
      p {
        line-height: 1.8rem;
      }
    }
    .misyon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 4.5rem;
      gap: 2rem;
      align-items: center;
      div {
        width: 85%;
        text-align: center;
        h1 {
          color: #c67657;
          margin-bottom: 2rem;
        }
        p {
          line-height: 1.8rem;
        }
      }
      img {
        width: 85%;
        box-shadow: 10px 10px 10px #b8a097;
      }
    }
    .vizyon {
      display: flex;
      padding: 4.5rem;
      gap: 2rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div {
        text-align: center;
        width: 85%;
        h1 {
          color: #c67657;
          margin-bottom: 2rem;
        }
        p {
          line-height: 1.8rem;
        }
      }
      img {
        display: none;
      }
      .vizyon_img {
        display: flex;
        width: 85%;
        box-shadow: 10px 10px 10px #b8a097;
      }
      margin-bottom: 10rem;
    }
  }
}
