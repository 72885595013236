.About {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem;
  position: relative;
  gap: 1%;
  .about-text {
    width: 50%;
    height: fit-content;
    padding: 5rem;
    background-color: #ff5714;
    color: white;
    border-radius: 50px;
    font-size: 90%;
    padding: 4.5rem;
    img {
      width: 30%;
    }
    h2 {
      margin: 2rem 0;
    }
    div {
      display: flex;
      gap: 2%;
      p {
        line-height: 1.5rem;
      }
    }
  }
  .about-video {
    width: 50%;
    height: 125%;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  a {
    color: white;
    h3 {
      margin-top: 2rem;
    }
    &:hover {
      color: #eeaa90;
    }
  }
}

@media screen and (max-width: 900px) {
  .About {
    display: flex;
    flex-direction: column;
    gap: 1%;
    margin: 20rem 0 30rem 0;
    .about-text {
      height: fit-content;
      width: 50%;
      text-align: center;
      div {
        display: flex;
        flex-direction: column;
        p {
          &:first-of-type {
            padding-bottom: 1rem;
            border-right: none;
            border-bottom: 1px dotted white;
          }
          &:nth-of-type(2) {
            padding-top: 1rem;
          }
        }
      }
    }
    .about-video {
      width: 80%;
      iframe {
        height: 180%;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .About {
    display: flex;
    flex-direction: column;
    gap: 1%;
    margin: 65% 0 60% 0;
    .about-text {
      height: fit-content;
      width: 50%;
      text-align: center;
      div {
        display: flex;
        flex-direction: column;
        p {
          &:first-of-type {
            padding-bottom: 1rem;
            border-right: none;
          }
        }
      }
    }
    .about-video {
      width: 80%;
      margin-bottom: 5rem;
      iframe {
        height: 120%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .About {
    display: flex;
    flex-direction: column;
    gap: 4%;
    margin: 65% 0 60% 0;
    .about-text {
      height: fit-content;
      width: 90%;
      text-align: center;

      img {
        width: 70%;
      }
      div {
        display: flex;
        flex-direction: column;
        p {
          &:first-of-type {
            padding-bottom: 1rem;
            border-right: none;
            border-bottom: 1px dotted white;
          }
        }
      }
    }
    .about-video {
      width: 80%;
      iframe {
        height: 150%;
      }
    }
  }
}
