* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Enriqueta", sans-serif;
  width: 100vw;

  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(../public/assets/bg4.jpg); */
  /* background-size: 200%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
a:visited {
  color: unset;
}

/* ----------------------Scrollbar-------------------------------------------------------- */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dc977c;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dc977c;
}

@media screen and (max-width: 400px) {
}
